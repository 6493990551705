<template>
    <v-dialog v-model="isOpen" width="1300">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Modifier une actualité
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <p class="text--secondary">
                    Modifiez les différents textes de l'actualité ou les modalités de diffusion, ajoutez ou gérez les photos ou les documents attachés.
                </p>

                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="actuality.title" label="Titre" class="mb-4" hide-details outlined dense />

                        <quill-editor :options="quillOption" class="mb-0" v-model="actuality.content" />

                        <v-row>
                            <v-col>
                                <p class="text--secondary pt-4">Modalités de diffusion</p>
                            </v-col>

                            <v-col>
                                <v-switch class="py-0" v-model="actuality.publish" label="Activer" hide-details />
                            </v-col>
                        </v-row>

                        <v-row class="my-0">
                            <v-col>
                                <DatePickerMenu v-model="actuality.startDate" label="Date de début" />
                            </v-col>

                            <v-col>
                                <DatePickerMenu v-model="actuality.endDate" label="Date de fin" />
                            </v-col>
                        </v-row>

                        <v-select v-model="actuality.context" @change="resetTargetCodes()" :items="actualitiesContexts" class="mb-3" item-value="id" item-text="label" label="Contexte" :menu-props="{ bottom: true, offsetY: true }" outlined dense hide-details></v-select>

                        <ProgramAutocomplete v-show="actuality.context === 'partners-programs'" ref="programAutocomplete" v-model="actuality.targetCodes" label="Programme(s)" multiple noDynamicSearch />
                        <PartnersAutocomplete v-show="actuality.context === 'partners-partners'" ref="partnersAutocomplete" v-model="actuality.targetCodes" label="Partenaire(s)" multiple noDynamicSearch />
                        <ProgramPartnerGroupAutocomplete v-show="actuality.context === 'partners-programs-groups' || actuality.context === 'partners-partners-groups'" ref="programPartnerGroupAutocomplete" v-model="actuality.targetCodes" label="Groupe(s)" multiple noDynamicSearch />
                    </v-col>

                    <v-col cols="6">
                        <v-row>
                            <v-col cols="3" v-for="attachment of getActualityAttachmentImages(actuality)" :key="attachment.id">
                                <v-card class="d-flex flex-column fill-height py-2" outlined>
                                    <v-card-title class="subtitle-2 pa-0 mx-2">
                                        {{ attachment.title }}
                                    </v-card-title>

                                    <v-divider class="mb-2" />

                                    <v-card-text class="flex-grow-1 pa-0 px-2">
                                        <a :href="getAttachmentUrl(attachment)" target="_blank">
                                            <v-img :src="getAttachmentUrl(attachment)" />
                                        </a>
                                    </v-card-text>

                                    <v-divider class="mt-2" />

                                    <v-card-actions class="px-0 pb-0">
                                        <v-row class="fill-height mx-2" align="end" no-gutters>
                                            <v-col>
                                                <UploadAttachmentDialog :actuality="actuality" :attachment="attachment" @updated="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-edit </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Editer cette image
                                                        </v-tooltip>
                                                    </template>
                                                </UploadAttachmentDialog>
                                            </v-col>

                                            <v-col class="text-right">
                                                <RemoveAttachmentDialog :attachment="attachment" @deleted="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-trash </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Supprimer cette image
                                                        </v-tooltip>
                                                    </template>
                                                </RemoveAttachmentDialog>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="3" v-for="attachment of getActualityAttachmentDocuments(actuality)" :key="attachment.id">
                                <v-card class="d-flex flex-column fill-height py-2" outlined>
                                    <v-card-title class="subtitle-2 pa-0 mx-2">
                                        {{ attachment.title }}
                                    </v-card-title>

                                    <v-divider class="mb-2" />

                                    <v-card-text class="flex-grow-1 d-flex justify-center">
                                        <v-icon color="primary"> fas fa-file </v-icon>
                                    </v-card-text>

                                    <v-divider class="mt-2" />

                                    <v-card-actions class="px-0 pb-0">
                                        <v-row class="fill-height mx-2" align="end" no-gutters>
                                            <v-col>
                                                <UploadAttachmentDialog :actuality="actuality" :attachment="attachment" @updated="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-edit </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Editer ce document
                                                        </v-tooltip>
                                                    </template>
                                                </UploadAttachmentDialog>
                                            </v-col>

                                            <v-col class="text-right">
                                                <RemoveAttachmentDialog :attachment="attachment" @deleted="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-trash </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Supprimer ce document
                                                        </v-tooltip>
                                                    </template>
                                                </RemoveAttachmentDialog>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="3" v-for="attachment of getActualityAttachmentYoutubeLinks(actuality)" :key="attachment.id">
                                <v-card class="d-flex flex-column fill-height py-2" outlined>
                                    <v-card-title class="subtitle-2 pa-0 mx-2">
                                        {{ attachment.title }}
                                    </v-card-title>

                                    <v-divider class="mb-2" />

                                    <v-card-text class="flex-grow-1 d-flex justify-center">
                                        <v-icon color="primary"> fab fa-youtube </v-icon>
                                    </v-card-text>

                                    <v-divider class="mt-2" />

                                    <v-card-actions class="px-0 pb-0">
                                        <v-row class="fill-height mx-2" align="end" no-gutters>
                                            <v-col>
                                                <UploadAttachmentDialog :actuality="actuality" :attachment="attachment" @updated="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-edit </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Editer cette vidéo youtube
                                                        </v-tooltip>
                                                    </template>
                                                </UploadAttachmentDialog>
                                            </v-col>

                                            <v-col class="text-right">
                                                <RemoveAttachmentDialog :attachment="attachment" @deleted="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-trash </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Supprimer cette vidéo youtube
                                                        </v-tooltip>
                                                    </template>
                                                </RemoveAttachmentDialog>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="3" v-for="attachment of getActualityAttachmentVideoLinks(actuality)" :key="attachment.id">
                                <v-card class="d-flex flex-column fill-height py-2" outlined>
                                    <v-card-title class="subtitle-2 pa-0 mx-2">
                                        {{ attachment.title }}
                                    </v-card-title>

                                    <v-divider class="mb-2" />

                                    <v-card-text class="flex-grow-1 d-flex justify-center">
                                        <v-icon color="primary"> fas fa-link </v-icon>
                                    </v-card-text>

                                    <v-divider class="mt-2" />

                                    <v-card-actions class="px-0 pb-0">
                                        <v-row class="fill-height mx-2" align="end" no-gutters>
                                            <v-col>
                                                <UploadAttachmentDialog :actuality="actuality" :attachment="attachment" @updated="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-edit </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Editer ce lien vidéo
                                                        </v-tooltip>
                                                    </template>
                                                </UploadAttachmentDialog>
                                            </v-col>

                                            <v-col class="text-right">
                                                <RemoveAttachmentDialog :attachment="attachment" @deleted="refreshAttachments()">
                                                    <template v-slot:activator="{ on: dialog }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                                <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                    <v-icon small> fas fa-trash </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Supprimer ce lien vidéo
                                                        </v-tooltip>
                                                    </template>
                                                </RemoveAttachmentDialog>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="3">
                                <UploadAttachmentDialog :actuality="actuality" @updated="refreshAttachments()">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-card class="d-flex align-center justify-center" style="height: 65px" outlined v-on="{ ...dialog, ...tooltip }">
                                                    <v-icon color="primary"> fas fa-plus </v-icon>
                                                </v-card>
                                            </template>
                                            Ajouter une image, un document ou une vidéo
                                        </v-tooltip>
                                    </template>
                                </UploadAttachmentDialog>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit" color="primary" small>
                    Valider
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, endOfDay } from 'date-fns';
import actualitiesMixin from '../../mixins/actualities.js';
import DatePickerMenu from '../widgets/DatePickerMenu.vue';
import RemoveAttachmentDialog from './RemoveAttachmentDialog.vue';
import UploadAttachmentDialog from './UploadAttachmentDialog.vue';
import ProgramAutocomplete from '../widgets/ProgramAutocomplete.vue';
import PartnersAutocomplete from '../widgets/PartnersAutocomplete.vue';
import ProgramPartnerGroupAutocomplete from '../widgets/ProgramPartnerGroupAutocomplete.vue';

export default {
    name: 'ActualityEditionDialog',

    mixins: [actualitiesMixin],

    props: {
        initActuality: { type: Object, required: true }
    },

    components: {
        DatePickerMenu,
        ProgramAutocomplete,
        PartnersAutocomplete,
        RemoveAttachmentDialog,
        UploadAttachmentDialog,
        ProgramPartnerGroupAutocomplete
    },

    data: () => ({
        isOpen: false,

        actuality: {},
        partnersFetched: false,
        programsFetched: false,
        groupsFetched: false,
        quillOption: {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['link'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'font': [] }],
                    [{ 'align': [] }]
                ]
            }
        }
    }),

    methods: {
        open() {
            this.isOpen = true;
        },

        async submit() {
            try {
                this.setLoading(true);

                if (this.actuality.endDate) {
                    this.actuality.endDate = format(endOfDay(new Date(this.actuality.endDate)), 'yyyy-MM-dd HH:mm:ss');
                }

                const body = {
                    actuality: {
                        ...this.actuality
                    }
                };

                const { success, err } = await this.repos.actualities.updateActuality(this.initActuality.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Information',
                        text: 'L\'actualité a bien été modifiée',
                        type: 'success'
                    });
                    this.$emit('updated');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue durant la modification de l\'actualité',
                    type: 'error'
                });
                console.error(err);
            } finally {
                this.setLoading(false);
            }
        },

        async refreshAttachments() {
            try {
                this.setLoading(true);

                const query = {
                    page: 1,
                    limit: 1,
                    include: 'attachments',
                    actualities: [this.actuality.id]
                };
                const { actualities, err } = await this.repos.actualities.getActualities(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.actuality.attachments = actualities[0].attachments;
                    this.$emit('updated');
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de l\'actualité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        resetTargetCodes() {
            this.actuality.targetCodes = [];
            this.$refs.programAutocomplete.reset();
            this.$refs.partnersAutocomplete.reset();
            this.$refs.programPartnerGroupAutocomplete.reset();
        }
    },

    watch: {
        'actuality.context'() {
            this.$nextTick(() => {
                switch (this.actuality.context) {
                    case 'partners-programs':
                        if (!this.programsFetched) {
                            this.programsFetched = true;
                            this.$nextTick(() => {
                                this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
                            });
                        }
                        break;
                    case 'partners-partners':
                        if (!this.partnersFetched) {
                            this.partnersFetched = true;
                            this.$nextTick(() => {
                                this.$refs.partnersAutocomplete.fetchPartners({ limit: 10000 });
                            });
                        }
                        break;

                    case 'partners-programs-groups':
                    case 'partners-partners-groups':
                        if (!this.groupsFetched) {
                            this.groupsFetched = true;
                            this.$nextTick(() => {
                                this.$refs.programPartnerGroupAutocomplete.fetchGroups({ limit: 10000 });
                            });
                        }
                        break;
                }
            });
        },

        isOpen() {
            this.actuality = JSON.parse(JSON.stringify(this.initActuality));
        }
    }
};
</script>
